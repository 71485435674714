<template>
<div class="bg_ac sub_cont">
    <div class="box_round">
        <h1>EFS SaaS 신청 완료</h1>
        <div class="icon"><img src="../../assets/img/icon_ac01.png"/></div>
        <div class="txt"><b class="fc_o">{{ loginUserData.cmpyNm }} {{loginUserData.userNm}}</b>님,<br>
        EFS SaaS 신청이 완료되었습니다.<br>
        자세한 사항은 담당자를 통해 연락드리겠습니다.</div>
        <div class="box_btn"><div @click="$router.push({ name:'Landing', params : { num : 8 }})" class="btn orange">서비스 문의하기</div></div>
    </div>
</div>
</template>

<script>

import { WOW } from 'wowjs'

export default {
  name: 'ApplicationCompleted',
  mounted() {
    new WOW().init();
  },
  computed :{
    loginUserData: function () {
      return this.$store.getters['loginUserData']
    },
  },
  data () {
    return {
    }
  },
}
</script>
<style scoped>

</style>
